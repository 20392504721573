
const digest = 'd13320948e3ce109e809d1e098e22129c8f136cdc23efacb741aa6d413cb75c3';
const css = `._invoiceAssignmentWarning_3hc14_1 {
  display: flex;
  align-items: center;
  color: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

._icon_3hc14_10 {
  margin-right: 0.625rem;
  flex: 0 0 0.875rem;
  height: 0.875rem;
}

._icon_3hc14_10 svg {
  width: 100%;
  height: 100%;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"invoiceAssignmentWarning":"_invoiceAssignmentWarning_3hc14_1","icon":"_icon_3hc14_10"};
export { css, digest };
  