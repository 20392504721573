
const digest = '508220087dd21e413f4a37704297c209a7907ab95bec91cd3ff471aa35cc58c6';
const css = `._column_1xzvz_1 {
  max-width: 14rem;
  text-transform: none;
  font-size: 0.875rem;
  font-weight: normal;
  margin: 0.5rem;
}

._iconWrapper_1xzvz_9 {
  position: relative;
}

._icon_1xzvz_9 {
  position: absolute;
  top: 0;
  transform: translateY(10%);
  left: 0.25rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"column":"_column_1xzvz_1","iconWrapper":"_iconWrapper_1xzvz_9","icon":"_icon_1xzvz_9"};
export { css, digest };
  