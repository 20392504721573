
const digest = 'cfa22d2295eda3d0ac206c71661756fe9654d6915b9c3bd71543f5580a9511fc';
const css = `._activeStatusIcon_oe6a3_1 {
  position: relative;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.5em;
  margin-top: -0.125em;
  vertical-align: middle;
  width: 0.5em;
  background-color: red;
}

._activeStatusIcon--Open_oe6a3_14 {
  background-color: var(--color-warning);
}

._activeStatusIcon--Submitted_oe6a3_18 {
  background-color: var(--color-green);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"activeStatusIcon":"_activeStatusIcon_oe6a3_1","activeStatusIcon--Open":"_activeStatusIcon--Open_oe6a3_14","activeStatusIconOpen":"_activeStatusIcon--Open_oe6a3_14","activeStatusIcon--Submitted":"_activeStatusIcon--Submitted_oe6a3_18","activeStatusIconSubmitted":"_activeStatusIcon--Submitted_oe6a3_18"};
export { css, digest };
  