
const digest = '14cee26d6eb3cd93b2dd6e92dfee395865c8fec43c3d55dff6b8cdbcfb51dd2e';
const css = `._spinner_3vod1_1 {
  width: 2rem;
  height: 2rem;
  color: var(--color-brand);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"spinner":"_spinner_3vod1_1"};
export { css, digest };
  